import React from 'react'
import MainPageCo from '../../components/MainPageContent/MainPageCo'
import AlapsakhyankCont from '../../components/AlapsakhyankContent/AlapsakhyankCont'
import KishanMorchaCont from '../../components/kishanMorcha/kishanMorchaCont'
import ObcMorchaContent from '../../components/obcMorchaContent/ObcMorchaCont'

const Mainpage = () => {
  return (
    <div>
 <MainPageCo/>
 {/* <AlapsakhyankCont/> */}
 {/* <KishanMorchaCont/> */}
 {/* <ObcMorchaContent/> */}
 {/* Nothing to show */}
    </div>
  )
}

export default Mainpage