import React from "react";
import "./mainPageCo.css";
import { Button } from "@mui/material";

const MainPageCo = () => {
  return (
    <div>
      <div className="background-image-div-home">
        <div className="home-button-morcha">
          <div>
            <Button variant="contained" color="info">
              <a href="/alapsakhyank-morcha">Alapsakhyank Morcha</a>
            </Button>
          </div>
          <div>
            <Button variant="contained" color="info">
              <a href="/kisan-morcha">Kisan Morcha</a>
            </Button>
          </div>
          <div>
            <Button variant="contained" color="info">
              <a href="/obc-morcha">Obc Morcha</a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageCo;
